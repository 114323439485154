.parent {
	width: 100%;
}

.parent h1,
.parent h2,
.parent h3,
.parent h4,
.parent h5,
.parent p {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}

.main-title {
	font-size: 3rem;
	margin-bottom: 2rem;
}

.parent p {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}

.parent hr {
	border-top: 1px solid var(--light-gray);
	margin: 0;
}

.parent .marginBottom-L {
	margin-bottom: 7rem;
}

.social-top {
	margin: 1rem 0 3rem 0;
}

/* CONTENEDOR, IMAGE */

.container {
	max-width: 1324px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
}

.banner-container{
	margin-top: 1rem;
}

.container-parent {
	width: 900px;
}

.image-container {
	display: flex;
	position: relative;
	background-color: var(--dark-black);
	min-height: 300px;
	width: 100%;
}


.image {
	width: 100% !important;
	height: auto !important;
	padding: 1px 0;
	cursor: pointer;
}

.rotate--45-dg {
	/* filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5); */
	width: 1rem;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.expand-container {
	display: flex;
	align-items: center;
	font-size: 2rem;
	padding: 0.3rem 0.7rem;
	background-color: var(--black);
	opacity: 0.8;
	position: absolute;
	top: 1rem;
	right: 1rem;
	color: var(--white);
	border-radius: 5px;
	font-weight: 400;
}

.expand-container span {
	margin-left: 1rem;
	font-size: 1.25rem;
}

.share-container {
	display: flex;
	align-items: center;
	font-size: 2rem;
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	font-weight: 400;
}

.grid-container {
	width: 100%;
	display: flex;
	justify-content: space-around;
	gap: 30px;
}

.container-list {
	width: 300px;
    padding-top: .938rem;
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.container-interesar{
	display: flex;
    flex-direction: column;
	width: 100%;
	margin-top: 15px;
}

@media (max-width: 768px) {

	.container-list {
		display: none;
	}
	.container-logo {
		flex-wrap: wrap;
		margin: .5rem;
	}

	.container-logo img {
		margin-left: 0;
	}
	.parent .marginBottom-L {
		margin-bottom: 2rem;
	}
}

@media (max-width: 650px) {
	.expand-container {
		font-size: 1.5rem;
	}

	.expand-container span {
		display: none;
	}

	.main-title {
		font-size: 2.2rem;
	}
}

@media (max-width: 480px) {
	.header-title {
		display: none;
	}
	.image-container {
		min-height: auto;
	}
}
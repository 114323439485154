.icon-author-social-media {
	width: 1.7rem;
	height: 1.7rem;
	border-radius: 100%;
	margin: 0rem 0.3rem;
	background-color: #216497;
	display: flex;
	justify-content: center;
	align-items: center;
  }

  .container-icon-social-media {
	display: flex;
}

  .icon {
	width: 0.8rem;
	color: rgb(253, 253, 253);
  }

  .facebook-icon {
	width: 0.7rem;
  }

  .twitter-icon {
	width: 1.0rem;
  }

  .container-author-social-media {
	width: auto;
	display: flex;
  }

.parent {
	margin: 1rem;
	padding: 0;
	font-family: 'Roboto', sans-serif;
}

.main-container {
	max-width: 1300px;
	margin: 0 auto;
}

.container {
	display: grid;
	grid-template-columns: 1fr 300px;
	column-gap: 2rem;
	margin: 1rem 0;
}

.detail-left {
	width: 100%;
	grid-column: 1 / 2;
	padding-right: 1rem;
}

.detail-right {
	width: 100%;
	grid-column: 2 / 3;
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
}

.banner {
	width: 100%;
}

.btn-container {
	width: 100%;
	margin: 4.25rem 0;
}

.button-load-more {
	background-color: var(--sky-blue);
	border: none;
	border-radius: 5px;
	color: var(--white);
	cursor: pointer;
	display: block;
	font-family: 'Roboto', sans-serif;
	font-size: 1.125rem;
	font-weight: 400;
	margin: 0 auto;
	padding: .75rem 2rem;
	text-align: center;
}

.button-load-more[disabled] {
    background-color: var(--light-gray);
    border: 1px solid var(--light-gray);
}

.button-load-more.hide {
    display: none;
}

.widget-block {
	margin: 2rem 0;
}

.column-widget-container-parent {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	row-gap: 1rem;
	column-gap: 2rem;
}

/***************************
------ PARA EL AUTOR -------
*****************************/

.author-container {
	padding-top: 0.563rem;
	margin: 3.125rem 0rem 1rem 1rem;
	display: flex;
	flex-direction: row;
	border: 1px solid transparent;
	border-bottom: 1px solid var(--shadow-gray);
	padding: .75rem 0 1.875rem 0;
}

.author-a img {
	width: 8.75rem;
	margin-right: 1.56rem;
	border-radius: 100%;
}

.author-name {
	color: var(--sky-blue);
	font-family: 'Roboto', sans-serif;
	font-size: 1.25rem;
	margin-left: 1.4rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.author-name h1 {
	font-size: 1.25rem;
	font-weight: 400;
	margin: 0 0 1.5rem 0;
}

.author-name a {
	color: var(--dark-black);
	font-size: 1rem;
	font-weight: 400;
	margin: 0.1rem 0 0.4rem 0;
}

.author-name p {
	color: var(--gray-text);
	font-size: 1rem;
	margin: 0.3rem 0 0 0;
	font-weight: 400;
}

.autor-email-btn {
	background-color: var(--gray);
	border-radius: 3px;
	color: var(--white);
	cursor:pointer;
	display: inline-block;
	font-size: 0.7rem;
	font-weight: 400;
	margin-bottom: .7rem;
	padding: 8px 25px;
	text-transform: uppercase;
	text-align: center;
}

@media (max-width: 980px) {
	.container {
		grid-template-columns: 1fr;
	}
	.btn-load-more {
		width: 90%;
		margin: 0 auto;
	}

	.detail-left {
		width: 100%;
		padding: 0;
		grid-column: 1 / 1;
	}
	
	.detail-right {
		width: 100%;
		padding-top: 0.938rem;
		grid-column: 1 / 1;
	}

	.column-widget-container-parent {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	.author-container {
		margin: 3.125rem 0rem 1rem 0rem;
	}
}

@media (max-width: 650px) {
	.column-widget-container-parent {
		grid-template-columns: 1fr;
	}

	.container-author-social-media {
		flex-direction: column;
	  }
}



@media (max-width: 480px) {

	  .author-container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 3.125rem 0rem 1rem 0rem;
	}

	.author-a img {
		width: 5.75rem;
		margin: 0;
	}

	.author-name {
		margin: 0;
		align-items: center;
	}

	.author-name h1 {
		font-size: 1.1rem;
		margin: 0 0 1rem 0;
	}

	.author-name a, .author-name p {
		font-size: .8rem;
	}

	.container-icon-social-media {
		justify-content: center;
		align-items: center;
	}
}
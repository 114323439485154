
.list-sections ul{
    display: flex;
    justify-content:flex-start;
    list-style: none;
    text-align: center;
    padding: 0;
    flex-wrap: wrap;
}
.list-sections ul a{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--light-gray);
    color: var(--black);
    border-radius: 0.5rem;
    font-weight: 400;
    padding: 1rem 2rem;
    transition: border 0.3s;
    text-transform: uppercase;
    margin: 0 0.5rem 0.5rem 0;
}

.list-sections ul a:hover{
    border: 1px solid var(--sky-blue);
}
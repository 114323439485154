.t-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    font-family: "Roboto", sans-serif;
    border-top: 1px solid var(--shadow-gray);
}

.mobile-only {
    display: none;
}

.title {
    font-size: 1.625rem;
    font-weight: 600;
    color: var(--sky-blue);
    text-transform: capitalize;
    margin: 1rem 0 1rem 0;
}

.subtitle {
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0 0 1rem 0;
    padding: 0;
    color: var(--gray-text);
}

.divider {
    height: 1px;
    background-color: var(--shadow-gray);
    border: none;
}

.description {
    font-size: 1rem;
    line-height: 1.375rem;
    color: var(--gray-text);
}

.image-container {
    height: 374px;
    width: 100%;
    display: block;
    max-width: 100%;
    max-height: 347px;
    position: relative;
}

.description-content {
    position: absolute;
    left: 30px;
    top: 10px;
    max-height: 245px;
    overflow: hidden;
    max-width: calc(100% - 60px);
    width: 350px;
    color: var(--white);
    line-height: 1.3rem;
}

.whatsapp {
    color: var(--white);
    background-color: green;
}

.twitter {
    background-color: var(--blue-twitter);
}

.facebook {
    background-color: var(--blue-facebook);
}

.icon {
    width: 1.2rem;
}

.twitter-icon-width-custom {
    width: 1.5rem;
}

.facebook-icon-width-custom {
 width: 0.8rem;
}

.social-media {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    bottom: 20px;
    left: 30px;
    color: var(--white);
    font-size: 1.3rem;
}

.social-icon {
    width: 40px;
    padding: 8px 9px;
    border-radius: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tags-container {
    position: absolute;
    width: 350px;
    right: 65px;
    top: 30px;
}

.related-tags-title {
    font-size: 0.75rem;
    color: var(--yellow);
    text-transform: uppercase;
    margin: 0 0 0 3px;
}

.tags-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.975rem;
    color: var(--white);
    font-weight: 400;
    padding: 5px 0 0 0;
    max-height: 210px;
    overflow: hidden;
}


.tag-anchor {
    padding: 7px 7px;
    border: 1px solid var(--white);
    margin: 3px;
}

.back {
    position: absolute;
    height: 100% !important;
    object-fit: 'contain';
}

.tags-content-no-image {
    display: flex;
    flex-wrap: wrap;
}

.tags-content-no-image .tag-anchor {
    border-color: var(--sky-blue);
    color: var(--sky-blue);
    margin: 1rem 7px 0 0;
}

@media (max-width: 980px) {
    .no-image {
        display: none;
    }

    .mobile-only {
        display: block;
    }
}

@media (max-width: 768px) {
    .title-tag-container {
        border-bottom: 1px solid var(--shadow-gray);
        padding-bottom: 2rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 480px) {
    .text {
        font-size: 1.5rem;
        margin: -0.8rem 1.45rem;
    }
}
.footer-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;
  background: #fff;
  margin-bottom: 1.875rem;
  width: 100%;
}

.footer-container a:hover {
  color: #216497;
}

.footer-container hr {
  border: 1px solid rgb(211, 211, 211);
  margin: 0;
  width: 100%;
}

.footer-social {
  display: flex;
  justify-content: center;
  list-style: none;
  font-family: IconFont;
  line-height: 1;
  font-size: 1rem;
  font-style: normal;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  text-decoration: inherit;
}

.footer-social-link {
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 100%;
  margin: 0rem 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #216497;
}

.footer-other-social-link {
  width: 1.7rem;
  height: 1.7rem;
  margin: 0rem 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-other-social {
  display: flex;
}

.icon {
  width: 0.9rem;
  color: rgb(253, 253, 253);
}

.facebook-icon {
  width: 0.7rem;
}

.twitter-icon {
  width: 1.5rem ;
}

.footer-logo {
  display: flex;
	flex-direction: column;
	align-items: center;
	height: 2.6rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.footer-logo-img {
  padding-left: 0.66rem;
  padding-right: 0.66rem;
  margin-bottom: 1rem;
}

.footer-supplements {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 2rem;
  color: var(--black);
  border-top: 1px solid var(--shadow-gray);
  border-bottom: 1px solid var(--shadow-gray);
  margin: 0.313rem 0rem;
  padding: 0;
}

.footer-supplements-item {
  margin: 0 0.3rem;
}

.columns-links-container {
  padding-top: 0.625rem;
  margin: auto;
  width: calc(calc(var(--containerWidth) + var(--streetSizeH)) / 1.30);
  display: grid;
  grid-template-columns: 10% 80% 10%;
  place-items: center;
}

.links-container {
  width: 14.375rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-family: "Roboto", sans-serif;
}
.links-container a {
  font-weight: 400;
}

.footer-links {
  text-align: center;
  margin: auto;
  list-style: none;
  font-family: "Roboto", sans-serif;
  line-height: 1;
  font-size: 1rem;
  font-weight: 400;
  color: var(--black);
  width: 100%;
  flex-wrap: wrap;
  line-height: 1.75rem;
  border-top: 1px solid var(--shadow-gray);
  border-bottom: 1px solid var(--shadow-gray);
  margin: 1rem 0;
  padding: 1rem .5rem;
}

.footer-institutional-container {
  display: flex;
  justify-content: center;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.625rem;
  justify-content: space-around;
}

.notifications-container {
  z-index: 100;
}

.notifications-container img {
  position: fixed;
  bottom: 2.5rem;
}

.footer-institutional {
  padding: 0 1rem;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 10% auto 10%;
}

.footer-institutional-afip {
  justify-self: end;
}

.footer-institutional:last-child {
  display: flex;
  justify-content: center;
}

.institutional-inner:last-child h5 {
  margin-bottom: 0;
}

.institutional-inner {
  display: flex;
  justify-content: center; 
  align-items: center;
  flex-wrap: wrap;
}

.institutional-inner h5 {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  margin: 0 0.7rem 0.7rem 0;
  font-weight: 600;
}

.brands {
  display: flex;
  align-items: center;
}

.brands a{
  margin:0 5px;
}

.brands:last-child a {
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .columns-links-container {
    width: 100%
  }
}

@media (max-width: 980px) {
  .notifications-container img {
    bottom: 22rem;
  }

  .institutional-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .institutional-inner:last-child h5 {
    margin-bottom: 0.7rem;
  }

  .columns-links-container{
    width: 95%;
  }
}

@media (max-width: 650px) {
  .footer-institutional {
    padding: 0 1rem 1rem;
  }

  .brands{
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  .notifications-container{
    display: none;
  }

  .columns-links-container{
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .footer-other-social {
    padding-top: 1rem;
  }

  .footer-container{
    position: absolute;
    left: 0;
  }

  .footer-supplements {
    margin-bottom: 0;
    width: 100%;
  }
}
@media (max-width: 480px){
  .footer-institutional{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:0;
  }
}

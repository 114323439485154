.author-box {
	display: grid;
	align-items: center;
	font-family: 'Roboto', sans-serif;
	margin: 0 1.6rem 0.625rem 0;
	width: auto;
	grid-template-columns: auto 1fr;
	column-gap: 1rem;
}

.author-logo img {
	border-radius: 100%;
	width: 3rem;
	height: auto;
}

.author-name-box {
	font-size: 1rem;
	font-weight: 700;
	display: flex;
}

.seguir-span {
	margin-bottom: 1rem;
}

.separator {
	margin: 0 .3rem;
}

.author-biography p {
	margin: 0;
	font-size: 1rem;
	font-weight: 700;
}

.seguir-span span {
	color: var(--sky-blue);
}

.seguir-name-box {
	background-color: var(--sky-blue);
	border-radius: 0.188rem;
	color: var(--white);
	font-size: 0.875rem;
	padding: 0.5rem 1rem 0.5rem 1rem;
	text-align: center;
}
.por {display: none;}
@media (max-width: 650px) {
	.separator {
		display: none;
	}
.por {display: block;}
	.author-name-box {
		display: block;
		font-size: .9rem;
	}
	.author-box {
		display:block;
		margin: 0 .5rem 0.625rem 0;
		font-size: .9rem;
	}
	.author-box:nth-child(3n){
	 border-left: 1px solid #CCC;
	 height: 1rem;
	 padding-left: .5rem;
	}
	.author-logo img {
	display: none;
	}
	.seguir-span {
		font-weight:bold;
	}
}
.megabanner-derecha {
    width: 300px;
    min-height: 600px;
    position: fixed;
    right: 0;
    top: 200px;
    z-index: 100;
    display: none;
}

@media screen and (min-width:1920px) {
    .megabanner-derecha {
        display: block;
    }
}
.img { 
	width: 100%;
	height: auto;
	object-fit: cover;
}

.container {
  padding: 0;
  list-style: none;
}

.container h2 {
  margin: 0 0 1rem 0;
  padding-bottom: .5rem;
  font-weight: 100;
  font-size: 1.5rem;
  border-bottom: 1px solid var(--light-gray);
}

.sections {
  padding: 0;
  list-style: none;
}

.note {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 1rem;
  padding-bottom: 3rem;
}

.note .image {
  width: 100%;
  object-fit: cover;
}

.note .text {
  width: 100%;
  display: flex;
  grid-column-start: span 2;
  flex-direction: column;
}

.note .text .subtitle {
  text-transform: uppercase;
  color: var(--sky-blue);
  font-weight: 700;
  margin: 0;
}

.note .text .title {
  color: var(--black);
  font-size: 1.5rem;
}

.note .text p {
  color: var(--gray);
  font-weight: 100;
}

@media (max-width: 768px) {
	.note {
    grid-template-columns: repeat(2, 1fr);
    border-bottom: 1px solid var(--light-gray);
  }

  .note .text {
    grid-column-start: 2;
  }

  .note .text .subtitle {
    font-size: 0.84rem;
  }

  .note .text .title {
    font-size: 1.12rem;
    margin-top: 0.2rem;
    font-weight: 400;
  }
  
  .note .text p {
    display: none;
  }
}
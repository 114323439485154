.time-update span {
	color: var(--sky-blue);
}

.separator {
	margin: 0 .3rem;
}

.dateTime-container {
	display: flex;
	flex-direction: row;
}

.date-section {
	display: flex;
	flex-direction: row;
	font-size: 0.8rem;
}

.slash-separator {
	font-size: 0.8rem;
}

@media (max-width: 650px) {
	.dateTime-container {
		flex-direction: column;
		align-items: center;
	}

	.slash-separator {
		display: none;
	}
}

@media (max-width: 480px) {
	.separator {
		display: none;
	}
}
/* ICONOS COMPARTIR */
.container-icons {
	display: flex;
}

.container-icons a {
	border-radius: 100%;
}

.icons {
	width: 2rem;
	height: 2rem;
	border-radius: 100%;
	margin: 0 .2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.8;
}

.color-close {
	background-color: var(--black);
}

.icon {
	width: 1rem;
	color: var(--white);
}

.facebook-icon {
	width: 0.8rem;
}

.twitter-icon {
	width: 1.5rem;
}

.left {
	justify-content: flex-start;
}

.center {
	justify-content: center;
}

.right {
	justify-content: flex-end;
}
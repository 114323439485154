.list-container {
    display: grid;
    grid-template-columns: 1fr;
    font-family: 'Roboto', sans-serif;
    color: var(--black);
    font-weight: 400;
}


.list-container p {
    font-weight: 400;
}

.note {
    display: grid;
    grid-template-columns: 30% 1fr;
    column-gap: 1rem;
    padding: 2rem 0;
    border-bottom: 1px solid var(--light-gray);
}

.img {
    width: 100%;
}

.subtitle {
    font-size: 0.875rem;
    color: var(--highlight-blue);
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 0.5rem 0;
}

.title {
    font-size: 1.25rem;
    margin: 0 0 0.5rem 0;
    font-weight: 400;
}

.summary {
    font-size: 0.875rem;
    font-weight: 400;
}

@media (max-width: 980px) {
    .summary {
        display: none;
    }

    .note {
        column-gap: 0.5rem;
    }
}

@media (max-width: 650px) {
	.title {
        font-size: 0.875rem;
    }
}
.icon {
    width: 0.6rem;
}

.pg-datepicker {
    max-width: 400px;
    font-family: 'Roboto', sans-serif;
}

.dp-header.top-rounded {
    border-radius: 5px 5px 0 0;
    padding: 3px 0 0 0;
}

.dp-header {
    display: flex;
    justify-content: space-between;
    background-color: var(--highlight-blue);
    color: var(--white);
    font-weight: bold;
    align-items: center;
}

.dp-header button {
    padding: 10px 10px;
    border: 0;
    background-color: inherit;
    color: var(--white);
    cursor: pointer;
}

.dp-content {
    border: 1px solid var(--highlight-blue);
}

.dp-content a{
    padding: 5px;
}

.pg-datepicker ol{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-row: repeat(6, auto);
    list-style: none;
    padding: 0;
    margin: 0;
}

.pg-datepicker .days-container {
    grid-template-rows: repeat(6, 1fr);
}

.pg-datepicker li {
    text-align: center;
    padding: 5px 0;
    position: relative;
}

.pg-datepicker .named-days {
    font-weight: 600;
    padding: 10px 0;
    color: var(--highlight-blue);
}

.invalid-day {
    color: var(--dark-shadow-gray);
}

.checkDay::before {
    content: '';
    width: 27px;
    height: 27px;
    position: absolute;
    border: 3px solid var(--ocean-blue);
    border-radius: 50%;
    left: 0px;
    right: 0;
    margin: 0 auto;
    top: -2px;
    z-index: -1;
}

.sunday li:first-child {
    grid-column-start: 1;
}
.monday li:first-child {
    grid-column-start: 2;
}
.tuesday li:first-child {
    grid-column-start: 3;
}
.wenesday li:first-child {
    grid-column-start: 4;
}
.thursday li:first-child {
    grid-column-start: 5;
}
.friday li:first-child {
    grid-column-start: 6;
}
.saturday li:first-child {
    grid-column-start: 7;
}

@media (max-width: 768px) {
    .pg-datepicker {
        margin: 0 auto;
    }
}
.container {
    position: fixed;
    top: 0px;
    min-height: 100%;
    min-width: 100%;
    left: 0px;
    right: 0px;
    margin: 0px;
    background: rgba(0, 0, 0, 0.9);
    z-index: 201;
    padding: 0px;
    overflow: hidden;
}

.closeButton{
    position: absolute;
    right: 1rem;
    cursor: pointer;
    top: 1rem;
    background: rgb(255, 255, 255);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    z-index: 6;
    border: none;
}

.closeButton svg{
    width: 18px;
}

.arrow {
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    z-index: 5;
}
.arrow svg{
    width: 16px;
    height: auto;
}

.slideContainer {
    max-width: 1316px;
    margin: 0;
    width: 100%;
    overflow: hidden;
    background-color: black;
}

.slideContainer img {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    height: auto;
}

.epigraph{
    position: absolute;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    max-width: 100%;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-family: "Roboto", Arial, Sans-serif;
    letter-spacing: -0.2px;
    line-height: 1.2rem;
    padding: 20px 20px;
}

.desktopOnly{
    display: inline;
}

.slideItem {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.2s ease-out;
}

.slideArrowLeft,
.slideArrowRight {
    background-color: white;
}

.slideArrowLeft {
    left: 15px;
    transform: rotate(180deg)
}

.slideArrowRight {
    right: 15px;
}

.slideActive {
    opacity: 1;
    transform: translateX(0);
}

.slidePreviewPrev {
    transform: translateX(-100%);
}

.slidePreviewNext {
    transform: translateX(100%);
}

@media (max-width: 1024px) {
    .epigraph{
        bottom: 100px;
    }
}

@media (max-width: 720px) {
    .desktopOnly{
        display: inline;
    }    
}

.section-hoy {
	width: 100%;
}

.parent {
	margin: 1rem;
	padding: 0;
	font-family: 'Roboto', sans-serif;
}

.main-container {
	max-width: 1300px;
	margin: 0 auto;
}

.grid-container {
	display: grid;
	grid-template-columns: 1fr 300px;
	column-gap: 2rem;
	row-gap: 1rem;
}

.detail-left {
	grid-column: 1 / 2;
	width: 100%;
	padding-top: 0.938rem;
}

.detail-right {
	grid-column: 2 / 3;
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	padding-top: 1.875rem;
}

.btn-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2rem;
  }
  
  .btn {
	background-color: var(--sky-blue);
	border: 0;
	border-radius: 0.3rem;
	color: var(--white);
	cursor: pointer;
	display: inline-block;
	font-family: 'Roboto', sans-serif;
	font-size: 1.125rem;
	font-weight: 400;
	margin-bottom: 0.7rem;
	padding: 1rem 3rem;
	text-transform: uppercase;
	text-align: center;
  }
  
  .btn[disabled] {
	background-color: var(--light-gray);
	border: 1px solid var(--light-gray);
  }
  
  .btn.hide{
	display: none;
  }

.section-suscribite {
	width: 100%;
}

.container-bannerHorizontal {
	width: 100%;
	margin: 5rem 0;
}

.widget-block {
	margin: 2rem 0;
}

.column-widget-container-parent {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	row-gap: 1rem;
	column-gap: 2rem;
}

@media (max-width: 980px) {
	.grid-container {
		grid-template-columns: 1fr;
	}

	.detail-left {
		width: 100%;
		padding: 0;
		grid-column: 1 / 1;
	}

	.detail-right {
		width: 100%;
		padding-top: 0.938rem;
		grid-column: 1 / 1;
	}

	.column-widget-container-parent {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 650px) {
	.column-widget-container-parent {
		grid-template-columns: 1fr;
		row-gap: 1rem;
	}
}
.icon {
    width: 1rem;
}

.overlay {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.9);
    z-index: 300;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.show {
    display: block;
    transition: "transform 3s ease-in-out"
}

.hide {
    display: none;
    /* transition: "transform 3s ease-in-out" */
}

.slider {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    height: 100vh;
}

.slider-img {
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

/****** RELATED *******/

.related-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    font-size: 1rem;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.related-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.related-buttons .icon-border {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    border: 3px solid var(--white);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.related-buttons span {
    font-size: 1rem;
    margin-left: 10px;
}

.related-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/****** CONTROLS ******/

.hide-footer .description-container,
.hide-footer .controls .actions {
    opacity : 0; 
    transition:opacity 1s;
}

.controls {
    z-index: 302;
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
    color: var(--white);
    font-weight: 400;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.controls span {
    display: flex;
    align-items: center;
}

.controls .actions {
    display: flex;
    bottom: 1rem;
    right: 1rem;
    flex-direction: column;
    align-items: center;
    position: absolute;
    transition:opacity 1s;
}

.controls .actions > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    margin: 5px 0 0 0;
}

.controls .actions > span {
    font-size: 1rem;
}

.controls .options {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border-radius: 50%;
    height: 40px;
    display: flex;
    
}

.controls .actions a,
.controls .options a {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.controls .options .close {
    background-color: rgba(51,51,51,.8);
    padding: 1rem;
    border-radius: 5px;
    margin-left: 1rem;
    font-size: 1rem;
}

.controls .actions a {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    border-radius: 50%;
    
    background-color: rgba(51,51,51,.8);
}

.controls .actions a:hover {
    opacity: .9;
}

.controls .actions a.arrows {
    color: var(--white);
    transition:opacity 1s;
}

/****** EPIGRAPHE ********/

.description-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background-color: rgba(34,34,34,.4);
    min-height: 100px;
    z-index: 301;
    display: flex;
    align-items: center;
    opacity: 1;
    transition:opacity 1s;
}

.description-container h3 {
    color: rgba(255,255,255,1);
    max-width: calc(100% - 300px);
    padding: 1rem 0 1rem 2rem;
    font-size: 0.875rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.button-close {
    display: none;
}

@media (max-width: 980px) {
    .slider-img {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .controls .actions {
        display: none;
    }

    .description-container h3 {
        width: 100%;
        max-width: 100%;
        padding: 1rem;
    }
    .description-container {
        top:80px;
        bottom: auto;
        background-color: rgba(34,34,34);
}
}

@media (max-width: 650px) {
    .button-close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: rgba(51,51,51,0.8);
        border-radius: 50%;
        cursor: pointer;
    }

    .controls .options a{
        display: none;
    }
}

@media (max-width: 480px) {
    .related-buttons {
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }
}